import req from "../methods/req"
import method from '../methods/method'

export const tripList = function (data){
    return method.get_list({
        url:'/manage/crm.attendance/tripList',
        ...data
    })
}

export const tripInfo = function (data){
    return req({
        url:'/manage/crm.attendance/tripInfo',
        ...data
    })
}

export const dailyList = function (data){
    return method.get_list({
        url:'/manage/crm.attendance/dailyList',
        ...data
    })
}

export const dailyInfo = function (data){
    return req({
        url:'/manage/crm.attendance/dailyInfo',
        ...data
    })
}

export const getDayReportForm = function (data){
    return method.get_list({
        url:'/manage/crm.attendance/getDayReportForm',
        ...data
    })
}


export const getDayReportInfo = function (data){
    return req({
        url:'/manage/crm.attendance/getDayReportInfo',
        ...data
    })
}


/**
 * @param {Object} data
 * 反馈管理页面接口  
 * getComplaintList 列表
 * getComplaintInfo 详情
 */


export const getComplaintList = function (data){
	return method.get_list({
	    url:'/manage/crm.feedback/getList',
	    ...data
	})
}


export const getComplaintInfo = function (data){
    return req({
        url:'/manage/crm.feedback/getInfo',
        ...data
    })
}



//新版对考勤

// 考勤项设置
export const saveItems = (data) => req({ url: '/manage/attendance.items/saveItems', ...data })
// 考勤项详情
export const getItemsDetail = (data) => req({ url: '/manage/attendance.items/getItemsDetail',...data})
// 操作记录
// 
// export const getOperationLogList = (data) => req({ url: '/manage/attendance.setting/getOperationLogList',...data})

export const getOperationLogList = function (data){
    return method.get_list({
        url:'/manage/attendance.setting/getOperationLogList',
        ...data
    })
}

export const categoryList = (data) => req({ url: '/manage/attendance.setting/category',...data})

// 配置项保存（考勤提醒及其他设置）
export const saveSetting = (data) => req({ url: '/manage/attendance.setting/saveSetting',...data})
// 配置项详情（考勤提醒及其他设置）
export const getSettingDetail = (data) => req({ url: '/manage/attendance.setting/getSettingDetail',...data})


// 获取列表
// export const getList = (data) => req({ url: '/manage/attendance.address/getList',...data})
export const getList = function (data){
	return method.get_list({
	    url:'/manage/attendance.address/getList',
	    ...data
	})
}

// 保存地址
export const addAddress = (data) => req({ url: '/manage/attendance.address/addAddress',...data})
// 导入
export const importAddress = (data) => req({ url: '/manage/attendance.address/importAddress',...data})
// 详情
export const getDetail = (data) => req({ url: '/manage/attendance.address/getDetail',...data})


/* *********************************************** 考勤 **********************************/
// 考勤组列表
export const getAttendanceSectionList = (data) => req({ url: '/manage/attendance.group/getList',...data}) 
export const getType = (data) => req({ url: '/manage/attendance.group/getType',...data}) 

export const getShiftType = (data) => req({ url: '/manage/attendance.shift/getType',...data}) 
export const getCycleType = (data) => req({ url: '/manage/attendance.cycle/getType',...data}) 
export const getAdjustmentType = (data) => req({ url: '/manage/attendance.shift_adjustment/getType',...data}) 


// 获取排班列表
export const getAttendanceCycleList = (data) => req({ url: '/manage/attendance.cycle/getList',...data}) 

// 排班
export const arrangeClassesCycle = (data) => req({ url: '/manage/attendance.cycle/click',...data}) 

// 智能排班列表
// export const getIntelligentSchedulingList = (data) => req({ url: '/manage/attendance.intelligent_scheduling/getList',...data}) 
export const getIntelligentSchedulingList = function (data){
	return method.get_list({
	    url:'/manage/attendance.intelligent_scheduling/getList',
	    ...data
	})
}

// 新增智能排班
export const addIntelligentSchedulingReq = (data) => req({ url: '/manage/attendance.intelligent_scheduling/addIntelligentScheduling',...data}) 

// 编辑智能排班
export const editIntelligentSchedulingReq = (data) => req({ url: '/manage/attendance.intelligent_scheduling/editIntelligentScheduling',...data}) 

// 删除智能排班
export const deleteIntelligentSchedulingReq = (data) => req({ url: '/manage/attendance.intelligent_scheduling/delete',...data})

// 获取周期方案以及排班考勤组
export const getIntelligentSchedulingCategory = (data) => req({ url: '/manage/attendance.intelligent_scheduling/category',...data})

// 应用排班周期
export const applySchedulingCycle = (data) => req({ url: '/manage/attendance.intelligent_scheduling/applicationIntelligentScheduling',...data})


// 获取可选班次
export const getVaildClassBatchData = (data) => req({ url: '/manage/attendance.shift/getCategory',...data}) 

// 获取班次详情
export const getClassBatchDetail = (data) => req({ url: '/manage/attendance.intelligent_scheduling/getDetail',...data}) 

// 调班列表
// export const getAdjustmentClassesList = (data) => req({ url: '/manage/attendance.shift_adjustment/getList',...data}) 
export const getAdjustmentClassesList = function (data){
	return method.get_list({
	    url:'/manage/attendance.shift_adjustment/getList',
	    ...data
	})
}

// 获取调班人员
export const getAdjustmentUserList = (data) => req({ url: '/manage/attendance.shift_adjustment/group',...data}) 

// 获取调班班次
export const getShiftTimeData = (data) => req({ url: '/manage/attendance.shift_adjustment/getShiftTime',...data}) 

// 单次调班
export const submitSingleShiftAdjustment = (data) => req({ url: '/manage/attendance.shift_adjustment/singleShiftAdjustment',...data}) 

// 批量调班
export const submitBatchShiftAdjustment = (data) => req({ url: '/manage/attendance.shift_adjustment/batchShiftAdjustment',...data}) 
export const getCycleCount = (data) => req({ url: '/manage/attendance.cycle/getCycleCount',...data}) 


/* *********************************************** 假期 **********************************/
// 获取周预览
export const getHolidayOverviewWeek = (data) => req({ url: '/manage/attendance.holiday_view/getHolidayOverviewWeek',...data}) 

// 获取月预览
export const getHolidayOverviewMonth = (data) => req({ url: '/manage/attendance.holiday_view/getHolidayOverviewMonth',...data}) 

// 请假记录
export const getHolidayLeaveRecordList = (data) => req({ url: '/manage/attendance.holiday_view/getLeaveRecordList',...data}) 

// 添加请假记录
export const saveHolidayLeaveData = (data) => req({ url: '/manage/attendance.holiday_view/saveHolidayLeave',...data}) 

// 获取手动添加假期详情
export const getHolidayLeaveDetail = (data) => req({ url: '/manage/attendance.holiday_view/getHanddleLeaveDetail',...data}) 

// 请假审批详情
export const getHolidayLeaveApprovalDetail = (data) => req({ url: '/manage/attendance.holiday_view/getApplyLeaveDetail',...data}) 

// 删除请假
export const deleteHolidayLeaveList = (data) => req({ url: '/manage/attendance.holiday_view/delHolidayLeave',...data}) 

// 请假记录导出 
export const exportHolidayLeaveRecord = () => '/manage/attendance.holiday_view/exportLeaveRecord'

// 月概览导出
export const exportHolidayMonthLeaveOverview = () => '/manage/attendance.holiday_view/exportMonthOverview'



// 获取假期余额列表
export const getHolidayBalanceList = (data) => req({ url: '/manage/attendance.holiday_balance/getHolidayBalanceList',...data}) 

// 获取余额下拉选项列表
export const getHolidayBalanceSelectList = (data) => req({ url: '/manage/attendance.holiday_balance/getSelect',...data}) 

// 获取假期余额表头
export const getHolidayBalanceTableList = (data) => req({ url: '/manage/attendance.holiday_balance/getTitle',...data}) 

// 获取假期类型
export const getHolidayLeaveType = (data) => req({ url: '/manage/attendance.holiday_rule/getLeaveType',...data}) 

// 获取假期余额
export const getHolidayLeaveBalance = (data) => req({ url: '/manage/attendance.holiday_balance/getTypeHolidayBalance',...data}) 

// 余额保存
export const saveHolidayBalance = (data) => req({ url: '/manage/attendance.holiday_balance/saveHolidayBalance',...data}) 

// 获取余额变更记录
export const getHolidayBalanceDetail = (data) => req({ url: '/manage/attendance.holiday_balance/getHolidayBalanceDetail',...data}) 

// 获取变动类型
export const getHolidayBalanceChangeType = (data) => req({ url: '/manage/attendance.holiday_balance/getChangeTypeSelect',...data}) 

// 导出变更记录
export const exportHolidayBalanceRecord = () => '/manage/attendance.holiday_balance/exportHolidayBalance'
 export const  exportHolidayBalanceRecord2 = () =>  '/manage/attendance.holiday_balance/exportHolidayBalanceRecord'
 

// 导出余额
export const exportHolidayBalance = () => '/manage/attendance.holiday_balance/exportHolidayBalance'


// 假期设置列表
export const getHolidaySettingList = (data) => req({ url: '/manage/attendance.holiday_setting/getSettingList',...data}) 

// 获取设置详情
export const getHolidaySettingDetail = (data) => req({ url: '/manage/attendance.holiday_setting/getSettingDetail',...data}) 

// 提交假期设置
export const submitHolidaySetting = (data) => req({ url: '/manage/attendance.holiday_setting/saveSetting',...data}) 

// 删除假期设置
export const deleteHolidaySetting = (data) => req({ url: '/manage/attendance.holiday_setting/delHolidaySetting',...data})

// 获取提醒设置
export const getHolidayNoticeSetting = (data) => req({ url: '/manage/attendance.holiday_setting/getNoticeSetting',...data})

// 获取设置日期
export const getHolidaySettingDays = (data) => req({ url: '/manage/attendance.holiday_setting/getSettingDays',...data})

// 保存提醒设置
export const saveHolidayNoticeSetting = (data) => req({ url: '/manage/attendance.holiday_setting/saveNoticeSetting',...data})

// 获取操作记录
export const getHolidayOperationList = (data) => req({ url: '/manage/attendance.holiday_setting/getOperationList',...data})

// 获取功能分类列表
export const getHolidayLogCategory = (data) => req({ url: '/manage/attendance.holiday_setting/getLogCate',...data})

// 获取操作记录人员列表
export const getHolidayOperationUserList = (data) => req({ url: '/manage/attendance.holiday_setting/getOperationUser',...data})






/* **************************************************************************************/

// 打卡wifi列表
// export const getWiFiList = (data) => req({ url: '/manage/attendance.wifi/getList',...data})

export const getWiFiList = function (data){
	return method.get_list({
	    url:'/manage/attendance.wifi/getList',
	    ...data
	})
}
//打卡wifi
export const addWifi = (data) => req({ url: '/manage/attendance.wifi/addWifi',...data})
export const importWifi = (data) => req({ url: '/manage/attendance.wifi/importWifi',...data})


// 获取wifi详情

export const getWiFiDetail = (data) => req({ url: '/manage/attendance.wifi/getDetail',...data})
export const editWifi = (data) => req({ url: '/manage/attendance.wifi/editWifi',...data})



// 扣款规则列表
// export const getDeductionList = (data) => req({ url: '/manage/attendance.deduction/getDeductionList',...data})

export const getDeductionList = function (data){
	return method.get_list({
	    url:'/manage/attendance.deduction/getDeductionList',
	    ...data
	})
}
// 基础规则分类
export const category = (data) => req({ url: '/manage/attendance.deduction/category',...data})

export const addDeduction = (data) => req({ url: '/manage/attendance.deduction/addDeduction',...data})

export const getDeductionDetail = (data) => req({ url: '/manage/attendance.deduction/getDetail',...data})

export const editDeduction = (data) => req({ url: '/manage/attendance.deduction/editDeduction',...data})


export const deleteDeduction = (data) => req({ url: '/manage/attendance.deduction/deleteDeduction',...data})


// 补贴/
// export const getSubsidyList = (data) => req({ url: '/manage/attendance.subsidy/getSubsidyList',...data})
export const getSubsidyList = function (data){
	return method.get_list({
	    url:'/manage/attendance.subsidy/getSubsidyList',
	    ...data
	})
}
export const getSubsidyTerm = (data) => req({ url: '/manage/attendance.subsidy/getSubsidyTerm',...data})

export const getShiftList = (data) => req({ url: '/manage/attendance.subsidy/getShiftList',...data})


export const addSubsidy = (data) => req({ url: '/manage/attendance.subsidy/addSubsidy',...data})
export const editSubsidy = (data) => req({ url: '/manage/attendance.subsidy/editSubsidy',...data})

export const deleteSubsidy = (data) => req({ url: '/manage/attendance.subsidy/deleteSubsidy',...data})




// 补卡规则
// export const getSupplementList = (data) => req({ url: '/manage/attendance.supplement/getSupplementList',...data})
export const getSupplementList = function (data){
	return method.get_list({
	    url:'/manage/attendance.supplement/getSupplementList',
	    ...data
	})
}
// 新增
export const addSupplement = (data) => req({ url: '/manage/attendance.supplement/addSupplement',...data})

// 编辑
export const editSupplement = (data) => req({ url: '/manage/attendance.supplement/editSupplement',...data})
// 删除
export const deleteSupplement = (data) => req({ url: '/manage/attendance.supplement/deleteSupplement',...data})
// 详情
export const getSupplementDetail = (data) => req({ url: '/manage/attendance.supplement/getDetail',...data})




// 加班规则getOvetTimeDetail,getOvertimeList,addOvertime,editOvertime,deleteOvertime,getOvertTimeDetail
// export const getOvertimeList = (data) => req({ url: '/manage/attendance.new_overtime/getOvertimeList',...data})
export const getOvertimeList = function (data){
	return method.get_list({
	    url:'/manage/attendance.new_overtime/getOvertimeList',
	    ...data
	})
}
// 新增
export const addOvertime = (data) => req({ url: '/manage/attendance.new_overtime/addOvertime',...data})

// 编辑
export const editOvertime = (data) => req({ url: '/manage/attendance.new_overtime/editOvertime',...data})
// 删除
export const deleteOvertime = (data) => req({ url: '/manage/attendance.new_overtime/deleteOvertime',...data})
// 详情
export const getOvertTimeDetail = (data) => req({ url: '/manage/attendance.new_overtime/getDetail',...data})


// 外勤规则 getFieldRuleList,addFieldRule,editFieldRule,deleteFieldRule,
// export const getFieldRuleList = (data) => req({ url: '/manage/attendance.field_rule/getFieldRuleList',...data})
export const getFieldRuleList = function (data){
	return method.get_list({
	    url:'/manage/attendance.field_rule/getFieldRuleList',
	    ...data
	})
}
// 新增
export const addFieldRule = (data) => req({ url: '/manage/attendance.field_rule/addFieldRule',...data})

// 编辑
export const editFieldRule = (data) => req({ url: '/manage/attendance.field_rule/editFieldRule',...data})
// 删除
export const deleteFieldRule = (data) => req({ url: '/manage/attendance.field_rule/deleteFieldRule',...data})
// 详情
export const getFieldDetail = (data) => req({ url: '/manage/attendance.field_rule/getDetail',...data})

   // 考勤组列表
export const getGroupList2 = (data) => req({ url: '/manage/attendance.group/getList',...data})

export const getGroupList = function (data){
	return method.get_list({
	    url:'/manage/attendance.group/getList',
	    ...data
	})
}

// 删除考勤组
export const removeGroup = (data) => req({ url: '/manage/attendance.group/removeGroup',...data})
// 复制考勤组
export const copy = (data) => req({ url: '/manage/attendance.group/copy',...data})
// 新增考勤组
export const addGroup = (data) => req({ url: '/manage/attendance.group/addGroup',...data})
// 编辑考勤组
export const editGroup = (data) => req({ url: '/manage/attendance.group/editGroup',...data})
// 考勤组详情
export const getGroupDetail = (data) => req({ url: '/manage/attendance.group/getDetail',...data})
export const ruleList = (data) => req({ url: '/manage/attendance.group/rule',...data})
// 班次列表
// export const getAttendanceShiftList = (data) => req({ url: '/manage/attendance.shift/getShiftList',...data})

export const getAttendanceShiftList = function (data){
	return method.get_list({
	    url:'/manage/attendance.shift/getShiftList',
	    ...data
	})
}
// 新增
export const addShift = (data) => req({ url: '/manage/attendance.shift/addShift',...data})
export const editShift = (data) => req({ url: '/manage/attendance.shift/editShift',...data})
// 详情
export const shiftDelete = (data) => req({ url: '/manage/attendance.shift/delete',...data})
export const getShiftDetail = (data) => req({ url: '/manage/attendance.shift/getDetail',...data})
// 个性化及分类
export const hommizationCategory = (data) => req({ url: '/manage/attendance.shift/hommization_category',...data})
//还原
export const reduction = (data) => req({ url: '/manage/attendance.shift/reduction',...data})
export const getCategory = (data) => req({ url: '/manage/attendance.shift/getCategory',...data})
//月考勤表
//加班记录
// export const getMonthOvertimeList = (data) => req({ url: '/manage/attendance.monthly/getOvertimeList',...data})
export const getMonthOvertimeList = function (data){
	return method.get_list({
	    url:'/manage/attendance.monthly/getOvertimeList',
	    ...data
	})
}

//获取加班类型
export const getOvertimeType = (data) => req({ url: '/manage/attendance.monthly/getOvertimeType',...data})
//添加加班记录
export const addMonthOvertime = (data) => req({ url: '/manage/attendance.monthly/addOvertime',...data})
//获取加班详情
export const getMonthOvertimeDetail = (data) => req({ url: '/manage/attendance.monthly/getOvertimeDetail',...data})

// 打卡记录
// export const getPunchList = (data) => req({ url: '/manage/attendance.monthly/getPunchList',...data})


export const getPunchList = function (data){
	return method.get_list({
	    url:'/manage/attendance.monthly/getPunchList',
	    ...data
	})
}
export const getPunchType = (data) => req({ url: '/manage/attendance.monthly/getPunchType',...data})
// 请加记录,
// export const getLeaveList = (data) => req({ url: '/manage/attendance.monthly/getLeaveList',...data})

export const getLeaveList = function (data){
	return method.get_list({
	    url:'/manage/attendance.monthly/getLeaveList',
	    ...data
	})
}

export const addLeave = (data) => req({ url: '/manage/attendance.monthly/addLeave',...data})
export const getLeaveDetail = (data) => req({ url: '/manage/attendance.monthly/getLeaveDetail',...data})
export const importLeave = (data) => req({ url: '/manage/attendance.import/importLeave',...data})
export const exportLeave = (data) => req({ url: '/manage/attendance.monthly/exportLeave',...data})
export const getLeaveData = (data) => req({ url: '/manage/attendance.monthly/getLeaveData',...data})
export const getLeaveType = (data) => req({ url: '/manage/attendance.monthly/getLeaveType',...data})
// 出差 
// export const getTravelList = (data) => req({ url: '/manage/attendance.monthly/getTravelList',...data})
export const getTravelList = function (data){
	return method.get_list({
	    url:'/manage/attendance.monthly/getTravelList',
	    ...data
	})
}
export const addTravel = (data) => req({ url: '/manage/attendance.monthly/addTravel',...data})
export const getTravelDetail = (data) => req({ url: '/manage/attendance.monthly/getTravelDetail',...data})
export const importTravel = (data) => req({ url: '/manage/attendance.monthly/importTravel',...data})
export const exportTravel = (data) => req({ url: '/manage/attendance.monthly/exportTravel',...data})
export const getTravelData = (data) => req({ url: '/manage/attendance.monthly/getTravelData',...data})
export const getTravelType = (data) => req({ url: '/manage/attendance.monthly/getTravelType',...data})

// 外出,
// export const getOutList = (data) => req({ url: '/manage/attendance.monthly/getOutList',...data})
export const getOutList = function (data){
	return method.get_list({
	    url:'/manage/attendance.monthly/getOutList',
	    ...data
	})
}

export const addOut = (data) => req({ url: '/manage/attendance.monthly/addOut',...data})
export const getOutDetail = (data) => req({ url: '/manage/attendance.monthly/getOutDetail',...data})
export const importOut = (data) => req({ url: '/manage/attendance.import/importOut',...data})
export const exportOut = (data) => req({ url: '/manage/attendance.monthly/exportOut',...data})
export const getOutData = (data) => req({ url: '/manage/attendance.monthly/getOutData',...data})
export const getOutType = (data) => req({ url: '/manage/attendance.monthly/getOutType',...data})
// 补卡
// export const  getRepairClockList = (data) => req({ url: '/manage/attendance.monthly/getRepairClockList',...data})


export const getRepairClockList = function (data){
	return method.get_list({
	    url:'/manage/attendance.monthly/getRepairClockList',
	    ...data
	})
}

export const getOvertimeData = (data) => req({ url: '/manage/attendance.monthly/getOvertimeData',...data})

export const  getRepairClockType = (data) => req({ url: '/manage/attendance.monthly/getRepairClockType',...data})

// 考勤月考勤记录
// export const  getMonthSummary = (data) => req({ url: '/manage/attendance.monthly/getMonthSummary',...data})

export const getMonthSummary = function (data){
	return method.get_list({
	    url:'/manage/attendance.monthly/getMonthSummary',
	    ...data
	})
}

export const  setMonthCategory = (data) => req({ url: '/manage/attendance.monthly/setMonthCategory',...data})

export const  getMonthId = (data) => req({ url: '/manage/attendance.monthly/getMonthId',...data})
export const  getSynchronizationUser = (data) => req({ url: '/manage/attendance.monthly/getSynchronizationUser',...data})

export const  getAccountingAttendance = (data) => req({ url: '/manage/attendance.monthly/getAccountingAttendance',...data})

export const  getTab = (data) => req({ url: '/manage/attendance.monthly/getTab',...data})

export const  importOvertime = (data) => req({ url: '/manage/attendance.import/importOvertime',...data})


export const  getMonthSummaryType = (data) => req({ url: '/manage/attendance.monthly/getMonthSummaryType',...data})

// 
export const  deleteAttendanceMonth = (data) => req({ url: '/manage/attendance.monthly/deleteAttendanceMonth',...data})

export const  getAttendancePersonnelUser = (data) => req({ url: '/manage/attendance.monthly/getAttendancePersonnelUser',...data})
export const  getAttendancePersonnelUserAdd = (data) => req({ url: '/manage/attendance.monthly/getAttendancePersonnelUserAdd',...data})
export const  getAttendancePersonnelUserDel = (data) => req({ url: '/manage/attendance.monthly/getAttendancePersonnelUserDel',...data})
export const  getAttendancePersonnelUserAffirm = (data) => req({ url: '/manage/attendance.monthly/getAttendancePersonnelUserAffirm',...data})

 // 日考勤 
 
 export const  getDailyTab = (data) => req({ url: '/manage/attendance.monthly/getDailyTab',...data})
 
 // export const  getDailyList = (data) => req({ url: '/manage/attendance.monthly/getDailyList',...data})
 
 export const getDailyList = function (data){
 	return method.get_list({
 	    url:'/manage/attendance.monthly/getDailyList',
 	    ...data
 	})
 }
 
 export const  getDailyDetail = (data) => req({ url: '/manage/attendance.monthly/getDailyDetail',...data})
 export const  getDailyExport = (data) => req({ url: '/manage/attendance.monthly/getDailyExport',...data})
 export const  getDailyType = (data) => req({ url: '/manage/attendance.monthly/getType',...data})
 
 // 明细 getDetailsList,getDetailList,getDetailsData,getDetailsType
 export const  getDetailsList = (data) => req({ url: '/manage/attendance.monthly/getDetailsList',...data})
 // export const  getDetailList = (data) => req({ url: '/manage/attendance.monthly/getDetailList',...data})
 
 export const getDetailList2 = function (data){
 	return method.get_list({
 	    url:'/manage/attendance.monthly/getDetailList',
 	    ...data
 	})
 }
 // 新
 export const  getDetailsData = (data) => req({ url: '/manage/attendance.monthly/getDetailsData',...data})
 export const  getDetailsType = (data) => req({ url: '/manage/attendance.monthly/getDetailsType',...data})
 // 个人详情/
 export const  getAttendanceMonthUser = (data) => req({ url: '/manage/attendance.monthly/getAttendanceMonthUser',...data})
 export const  getAttendanceMonthUserTab = (data) => req({ url: '/manage/attendance.monthly/getAttendanceMonthUserTab',...data})
 
 // 假期方案
 export const  getHolidayRuleList = (data) => req({ url: '/manage/attendance.holiday_rule/getHolidayRuleList',...data})
 export const  saveHolidayRule = (data) => req({ url: '/manage/attendance.holiday_rule/saveHolidayRule',...data})
 export const  getHolidayRuleDetail = (data) => req({ url: '/manage/attendance.holiday_rule/getHolidayRuleDetail',...data})
 export const  copyHolidayRule = (data) => req({ url: '/manage/attendance.holiday_rule/copyHolidayRule',...data})
 
 
 export const  delHolidayRule = (data) => req({ url: '/manage/attendance.holiday_rule/delHolidayRule',...data})
 export const  getSortSelect = (data) => req({ url: '/manage/attendance.holiday_rule/getSortSelect',...data})
 // export const  getLeaveType = (data) => req({ url: '/manage/attendance.holiday_rule/getLeaveType',...data})
 export const  getLeaveRuleSelect = (data) => req({ url: '/manage/attendance.holiday_rule/getLeaveRuleSelect',...data})
 
 
 export const  getGrantRuleSelect = (data) => req({ url: '/manage/attendance.holiday_rule/getGrantRuleSelect',...data})
 export const  getUserStatus = (data) => req({ url: '/manage/attendance.holiday_rule/getUserStatus',...data})
 export const  getDeductionSelect = (data) => req({ url: '/manage/attendance.holiday_rule/getDeductionSelect',...data})
 // 手动调整 
 export const  getHolidayRecordList = (data) => req({ url: '/manage/attendance.holiday_handdle/getHolidayRecordList',...data})
 export const  getHolidayHanddleDetail = (data) => req({ url: '/manage/attendance.holiday_handdle/getHolidayHanddleDetail',...data})
 export const  editHolidayBalance = (data) => req({ url: '/manage/attendance.holiday_handdle/editHolidayBalance',...data})
 export const  deleteHolidayRecord = (data) => req({ url: '/manage/attendance.holiday_handdle/delHanddleHolidayRecord',...data})
 export const  exportHolidayHaddleRecord = () => '/manage/attendance.holiday_handdle/exportHanddleRecord'

 
 // 导入导出
 export const  exportCycle = (data) => req({ url: '/manage/attendance.export/exportCycle',...data})
 export const  importCycle = (data) => req({ url: '/manage/attendance.import/importCycle',...data})
 export const  exportDetailsList = (data) => req({ url: '/manage/attendance.export/exportDetailsList',...data})
 export const  exportMonthSummary = (data) => req({ url: '/manage/attendance.export/exportMonthSummary',...data})
 export const  importMonthSummary = (data) => req({ url: '/manage/attendance.import/importMonthSummary',...data})
 export const  importPunchList = (data) => req({ url: '/manage/attendance.import/importPunchList',...data})
 export const  exportAdjustment = (data) => req({ url: '/manage/attendance.export/exportAdjustment',...data})
 export const  importAdjustment = (data) => req({ url: '/manage/attendance.import/importAdjustment',...data})
 
 
 export const  addPunchList = (data) => req({ url: '/manage/attendance.monthly/addPunchList',...data})

